const { dayjs } = window;
const currentDay = dayjs && dayjs().format('DD');
const currentMonth = dayjs && dayjs().format('MM');
const prevMonth = dayjs && dayjs().subtract(1, 'month').format('MM');
const nextMonth = dayjs && dayjs().add(1, 'month').format('MM');
const currentYear = dayjs && dayjs().format('YYYY');
const events = [
   
  {
    title: 'Conference',
    start: `${currentYear}-${currentMonth}-${currentDay}`,
    description:
      'The Milken Institute Global Conference gathered the best minds in the world to tackle some of its most stubborn challenges. It was a unique experience in which individuals with the power to enact change connected with experts who are reinventing health, technology, philanthropy, industry, and media.',
    className: 'bg-soft-success',
    allDay: true,
    schedules: [
      {
        title: 'Reporting',
        start: `${currentYear}-${currentMonth}-${currentDay} 11:00:00`,
        description:
          'Time to start the conference and will briefly describe all information about the event.  ',
        className: 'event-bg-soft-success',
      },
      {
        title: 'Lunch',
        start: `${currentYear}-${currentMonth}-${currentDay} 14:00:00`,
        description: 'Lunch facility for all the attendance in the conference.',
        className: 'event-bg-soft-success',
      },
      {
        title: 'Contest',
        start: `${currentYear}-${currentMonth}-${currentDay} 16:00:00`,
        description: 'The starting of the programming contest',
        className: 'event-bg-soft-success',
      },
      {
        title: 'Dinner',
        start: `${currentYear}-${currentMonth}-${currentDay} 22:00:00`,
        description: 'Dinner facility for all the attendance in the conference',
        className: 'event-bg-soft-success',
      },
    ],
  },
  {
    title: `ICT Expo ${currentYear} - Product Release`,
    start: `${currentYear}-${currentMonth}-16 10:00:00`,
    description: `ICT Expo ${currentYear} is the largest private-sector exposition aimed at showcasing IT and ITES products and services in Switzerland.`,
    end: `${currentYear}-${currentMonth}-18 16:00:00`,
    className: 'bg-soft-warning',
  },
  {
    title: 'Meeting',
    start: `${currentYear}-${currentMonth}-07 10:00:00`,
    description:
      'Discuss about the upcoming projects in current year and assign all tasks to the individuals',
  },
  {
    title: 'Contest',
    start: `${currentYear}-${currentMonth}-14 10:00:00`,
    description:
      'PeaceX is an international peace and amity organisation that aims at casting a pall at the striking issues surmounting the development of peoples and is committed to impacting the lives of young people all over the world.',
  },
  {
    title: 'Event With Url',
    start: `${currentYear}-${currentMonth}-23`,
    description:
      'Sample example of a event with url. Click the event, will redirect to the given link.',
    className: 'bg-soft-success',
    url: 'http://google.com',
  }, 
  {
    title: 'Birthday Party',
    start: `${currentYear}-${nextMonth}-05`,
    description: 'Will celebrate birthday party with my friends and family',
    className: 'bg-soft-primary',
  },
  {
    title: 'Click for Google',
    url: 'http://google.com/',
    start: `${currentYear}-${prevMonth}-10`,
    description:
      'Applications are open for the New Media Writing Prize 2020. The New Media Writing Prize (NMWP) showcases exciting and inventive stories and poetry that integrate a variety of formats, platforms, and digital media.',
    className: 'bg-soft-primary',
  },
];

export default events;
